import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PageTemplate from "../templates/page";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			featureBg: wpMediaItem(title: { eq: "feature-background" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				seoFieldGroups {
					metaTitle
					metaDescription
					# localBusinessSchema
					openGraphDescription
					openGraphTitle
					# productSchema
					image {
						altText
						sourceUrl
						publicUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
				pageFields {
					pageSections {
						... on WpPage_Pagefields_PageSections_HeroSection {
							fieldGroupName
							heading
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_FeatureSection1 {
							fieldGroupName
							alignment
							body
							heading
							hasQuote
							hasReverseInMobile
							button {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							quoteDetails {
								name
								quote
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
								fieldGroupName
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout3 {
							fieldGroupName
							hasLogo
							hasText
							button {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							heading
							logoImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout17 {
							fieldGroupName
							body
							heading
							mapLink
						}
						... on WpPage_Pagefields_PageSections_SectionLayout4 {
							fieldGroupName
							heading
						}
						... on WpPage_Pagefields_PageSections_SectionLayout5 {
							fieldGroupName
							contentLeft
							contentRight
							heading
						}
						... on WpPage_Pagefields_PageSections_SectionLayout6 {
							fieldGroupName
							heading
							button {
								target
								title
								url
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout7 {
							fieldGroupName
							body
							email
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_FeatureLayout1 {
							fieldGroupName
							body
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							heading
							items {
								body
								fieldGroupName
								heading
								logo {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							ctaLink {
								target
								title
								url
							}
							button {
								target
								title
								url
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout9 {
							fieldGroupName
							heading
							logos {
								fieldGroupName
								link
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout10 {
							fieldGroupName
							body
							button {
								target
								title
								url
							}
							heading
							items {
								body
								fieldGroupName
								heading
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout11 {
							fieldGroupName
							heading
						}
						... on WpPage_Pagefields_PageSections_SectionLayout13 {
							fieldGroupName
							backgroundColour
							body
							heading
							items {
								body
								fieldGroupName
								heading
							}
							textColour
							button {
								target
								title
								url
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout14 {
							fieldGroupName
							body
							heading
							itemDescription
							items {
								body
								fieldGroupName
								heading
								logo {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							ctaLink {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout15 {
							fieldGroupName
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							reviews {
								... on WpReview {
									id
									reviewFields {
										companytitle
										name
										review
									}
								}
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout16 {
							fieldGroupName
							logos {
								fieldGroupName
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
												width: 140
											)
										}
									}
								}
								link
							}
						}
						... on WpPage_Pagefields_PageSections_SectionLayout18 {
							fieldGroupName
							items {
								body
								fieldGroupName
								heading
								link
							}
						}
					}
				}
			}
		}
	`);

	return <PageTemplate data={{ ...data, isHomePage: true }} />;
};

export default IndexPage;
